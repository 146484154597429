/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import { isEurStore } from '../../util/FrontRunner/Store';

// Import style
import './WhyUsBlock.style.scss';

export class WhyUsBlockComponent extends PureComponent {
    static defaultProps = {
        // Add any default props if needed
    };

    render() {
        let cls = '';
        if (!isEurStore()) {
            cls = 'fourItem';
        }

        return (
            <div className="staticWhyUsWrap">
                <div className="container">
                    <div className="whyFrText">
                        { __('Why Front Runner') }
                    </div>
                    <h2>
                        { __('Born in Africa. Proven around the world.') }
                    </h2>
                    <div className="staticWhyUsBox">
                        <ul className={ cls }>
                            <li>
                            <div className="lfImg">
                                <FontAwesomeIcon icon={ faCheck } />
                            </div>
                            <div className="rlText">
                                { __('High-quality adventure gear') }
                            </div>
                            </li>
                            <li>
                            <div className="lfImg">
                                <FontAwesomeIcon icon={ faCheck } />
                            </div>
                            <div className="rlText">
                                { __('Built off-road tough') }
                            </div>
                            </li>
                            <li>
                            <div className="lfImg">
                                <FontAwesomeIcon icon={ faCheck } />
                            </div>
                            <div className="rlText">
                                { __('Manufactured in South Africa') }
                            </div>
                            </li>
                            { !isEurStore() && (
                            <li>
                                <div className="lfImg">
                                <FontAwesomeIcon icon={ faCheck } />
                                </div>
                                <div className="rlText">
                                { __('30-day money back guarantee') }
                                </div>
                            </li>

                            ) }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhyUsBlockComponent;
