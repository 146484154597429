/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { map } from 'lodash';
import { PureComponent } from 'react';
import LazyLoad from 'react-lazy-load';
import Slider from 'react-slick';

import Link from 'Component/Link';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import style
import './RackFamilyBlock.style.scss';

export class RackFamilyBlockComponent extends PureComponent {
    static defaultProps = {
        // Add any default props if needed
    };

    renderRackFamilyMap = (item) => (
        <div className="item">
            <div className="container">
                <div className="content-wrapper">
                    <h3>{ item.title_h3 }</h3>
                    <h4>{ item.title_h4 }</h4>
                    <p>{ item.description }</p>
                    <div>
                        <Link
                          block="Homepage"
                          elem="Link btn btn-primary"
                          to={ item.link }
                        >
                            { __('Explore Features') }
                        </Link>
                    </div>
                </div>
            </div>
            <div className="imgSec">
                <picture>
                    <source media="(max-width: 800px)" srcSet={ item.image_mobile } />
                    <source media="(min-width: 801px)" srcSet={ item.image } />
                    <img src={ item.image } alt={ item.title_h4 } loading="lazy" width="700" height="400" />
                </picture>
            </div>
        </div>
    );

    render() {
        const imgUrl1 = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimline-ii-rack-v2.jpg';
        const imgUrl1Mobile = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimline-ii-rack-mobile.jpg';
        const imgUrl2 = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimsport-rack-v2.jpg';
        const imgUrl2Mobile = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimsport-rack-mobile.jpg';
        const imgUrl3 = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimpro-van-rack-v2.jpg';
        const imgUrl3Mobile = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/slimpro-van-rack-mobile.jpg';
        const imgUrl4 = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/pro-bed-rack-v3.jpg';
        const imgUrl4Mobile = 'https://www.frontrunneroutfitters.com/media/landing-pages/home/pro-bed-rack-mobile.jpg';

        const data = [
            {
                title_h3: __('Meet our racks'),
                title_h4: __('Slimline II Roof Rack'),
                description: __('Recognized globally as the original and best overland roof rack, the strong Slimline II\'s modular design allows customization with a wide range of accessories for all load-hauling adventure lifestyles.'),
                image: imgUrl1,
                image_mobile: imgUrl1Mobile,
                link: '/racks/slimline-ii-roof-rack'
            },
            {
                title_h3: __('Meet our racks'),
                title_h4: __('Slimsport Rack'),
                description: __('Sleek, low-profile, and lightweight, the Slimsport Rack enhances vehicle aesthetics-ideal for loading adventure gear today and city cruising tomorrow. Customize it with various accessories for any adventure.'),
                image: imgUrl2,
                image_mobile: imgUrl2Mobile,
                link: '/racks/slimsport-roof-rack'
            },
            {
                title_h3: __('Meet our racks'),
                title_h4: __('SlimPro Van Rack'),
                description: __('The Slimpro Van Rack\'s low-profile and versatile design maximizes storage and enhances the gear-carrying capacity for van life adventures, weekend getaways, or hauling work materials.'),
                image: imgUrl3,
                image_mobile: imgUrl3Mobile,
                link: '/racks/slimpro-van-rack'
            },
            {
                title_h3: __('Meet our racks'),
                title_h4: __('Pro Bed Rack'),
                description: __('The ultimate truck bed storage solution that provides additional strength and rigidity to any load bed. Enjoy easy side access and unlimited gear configurations for a fully customizable load bed solution that offers maximum functionality.'),
                image: imgUrl4,
                image_mobile: imgUrl4Mobile,
                link: '/racks/pro-bed-rack'
            }
        ];

        return (
            <LazyLoad>
                <div id="meetOurRacks" className="home_page_before_footer_section">
                    <Slider
                      className="owl-theme"
                      loop
                      margin={ 0 }
                      nav={ false }
                      items={ 1 }
                      dots
                      autoplay={ false }
                      lazyLoad
                    >
                        { map(data, this.renderRackFamilyMap) }
                    </Slider>
                </div>
            </LazyLoad>
        );
    }
}

export default RackFamilyBlockComponent;
