/* eslint-disable max-len */
export const RETURN_FORM_GRAPHQL_URL_LIVE = 'https://fsync.frontrunneroutfitters.com/graphql/ReturnMerchandiseAuthorization';

export const RETURN_FORM_GRAPHQL_URL_STAGING = 'https://fsync.frontrunneroutfitters.com/graphql/ReturnMerchandiseAuthorization';

export const MAX_COUNT = 5;

export const MAX_FILE_SIZE = 8192;

export const PLEASE_SELECT = { value: '', label: __('Please Select') };

export const REASON_FOR_RETURN = [
    { value: 'damaged_products', label: __('Damaged product(s)') },
    { value: 'defective_products', label: __('Defective product(s) ') },
    { value: 'not_what_i_wanted/thought_it_was_going_to_be', label: __('Not what I wanted/thought it was going to be') },
    { value: 'doesnt_fit_for_my_purpose', label: __('Doesn\'t fit for my purpose') },
    { value: 'purchased_too_many', label: __('Purchased too many') },
    { value: 'other', label: __('Other') }
];

export const B2C_PREFFERD_OUTCOME = [
    { value: 'Refund purchase', label: __('Refund purchase') },
    { value: 'Replace product ', label: __('Replace product') }
];

export const B2B_PREFFERD_OUTCOME = [
    { value: 'refund_purchase', label: __('Refund purchase') },
    { value: 'replace_product', label: __('Replace product') },
    { value: 'credit_my_account', label: __('Credit my account') }
];
