/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { connect } from 'react-redux';

import RackFamilyBlockComponent from './RackFamilyBlock.component';

/** @namespace Component/RackFamilyBlock/Container/mapStateToProps */
export const mapStateToProps = () => ({
    // Add any state mappings if needed
});

/** @namespace Component/RackFamilyBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    // Add any dispatch mappings if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(RackFamilyBlockComponent);
